import {getDateByTimestamp, getMinutesDiff} from "@/shared/air-datepicker";
import {useCallback} from "@/shared/useCallback";
import {paymentResultUrl} from "@/entity/order/url/payment-result.url";
import {orderStore} from "@/entity/order/store/order.store";
import {confirmBuy} from "@/widgets/confirm-buy";
import {cruiseListStore} from "@/entity/cruise/store/cruise-list.store";
import {sendVKAnalyticsEvent, sendVKAnalyticsGoal} from "@/shared/analytics/vk";
import {reachYMGoal} from "@/shared/analytics/ym";
import {useActionModal} from "@/shared/ui/action-modal";

const setupSavedOrder = () => {
    const order = orderStore.getSavedOrder();
    let result = null;
    if (order !== null) {
        const {passengerListState, timestamp, orderContacts, cruiseState, acquiringUrl, price} = order;
        result = [acquiringUrl, timestamp, price];
        orderStore.setOrderContacts(orderContacts);
        orderStore.setCruise(cruiseState);
        const setupUserList = () => {
            orderStore.setDate(getDateByTimestamp(cruiseState.date_starts_at_timestamp));
            orderStore.setDirection(cruiseState.destinations[0]['id']);
            orderStore.setPassengerMap(new Map(passengerListState.map(([key, {birthday, ...passenger}]) => ([
                key,
                {
                    birthday: new Date(birthday),
                    ...passenger
                }]))));
        };
        if (cruiseListStore.cruisesExist()) {
            setupUserList();
        } else {
            let userListSetup = () => {
                setupUserList();
                userListSetup = () => {
                };
            };
            cruiseListStore.useEffect(userListSetup)
        }
    }

    return result;
};
const cleanupSavedOrder = () => {
    orderStore.clearOrderRecord();
    orderStore.setOrderContacts({email: null, phone: null});
    orderStore.setCruise(null);
    orderStore.setDate(null);
    orderStore.setDirection(null);
    orderStore.setPassengerMap(new Map());
};

export const createPaymentFlow = ({onDecline = null} = {}) => {
    const onDeclineCb = useCallback(onDecline);
    let acquiringUrl = null;
    const openAcquiring = () => {
        window.location.href = acquiringUrl;
        confirmModal.close();
    };
    const confirmModal = confirmBuy({
        onConfirm: async () => {
            if (acquiringUrl === null) {
                let error;
                reachYMGoal('FinishCheckout');
                confirmModal.startLoader();
                [acquiringUrl, error] = await orderStore.createCrsOrder();
                confirmModal.stopLoader();
                if(acquiringUrl === null){
                    useActionModal({
                        heading: 'Ошибка',
                        text: error ? error : 'Не удалось создать заказ, пожалуйста повторите оформление.',
                    });
                } else {
                    orderStore.saveOrder(acquiringUrl);
                    openAcquiring();
                }
            } else {
                openAcquiring();
            }
        },
        onDecline: () => {
            if (acquiringUrl !== null) {
                cleanupSavedOrder();
                window.location.href = window.location.origin;
            }
            confirmModal.close();
            onDeclineCb.call()
        },
    });

    const paymentUrl = paymentResultUrl();
    if (paymentUrl.isPaymentResult()) {
        if (paymentUrl.isSuccced()) {
            const [,,order_price] = setupSavedOrder();

            useActionModal({
                heading: 'Поздравляем!',
                text: 'Оплата прошла успешно! Билеты мы Вам прислали на указанную Вами почту.'
            });
            sendVKAnalyticsEvent('purchase');
            sendVKAnalyticsGoal('purchase');
            reachYMGoal('Purchase', {order_price});

        } else if (paymentUrl.isError()) {
            const savedOrder = setupSavedOrder();
            if (savedOrder) {
                const diff = getMinutesDiff(new Date(), new Date(savedOrder[1]));
                if (diff > 10) {
                    acquiringUrl = null;
                    cleanupSavedOrder();
                } else {
                    acquiringUrl = savedOrder[0];
                }
            }
            useActionModal({
                heading: 'Ошибка!',
                text: 'К сожалению оплата не прошла! Попробуйте оформить заказ заново.'
            });
        }
    }

    const isPaymentRetry = () => acquiringUrl !== null;

    return {
        start: () => {
            confirmModal.show();
        },
        onDecline: onDeclineCb.set,
        isPaymentRetry,
    }
};
