import {useCallback} from "@/shared/useCallback";

export function createValidator(
    {
        factory = () => new Pristine(),
        onValid = () => {
        },
        onInvalid = () => {
        }
    } = {}
) {
    const onValidCb = useCallback(onValid);
    const onInvalidCb = useCallback(onInvalid);

    let validator = factory();
    let errorValidated = false;
    const validate = (data) => {
        validator.reset();
        setTimeout(() => {
            let resultCb;
            if (validator.validate()) {
                errorValidated = false;
                resultCb = onValidCb.call;
            } else {
                errorValidated = true;
                resultCb = onInvalidCb.call;
            }
            return resultCb(data);
        }, 100);
    };
    const update = () => {
        validator.destroy();
        validator = factory();
        if (errorValidated) {
            validator.validate(undefined, true);
        }
    };

    return {
        validate,
        update,
        onValid: onValidCb.set,
        reset: validator.reset
    }
}
