import {useCallback} from "@/shared/useCallback";
import {createPhoneInputMask} from "@/shared/ui/phone-input-mask";
import {createSelectMenuDark} from "@/shared/ui/select-menu-dark";
import {reachYMGoal} from "@/shared/analytics/ym";
import {cruiseListStore} from "@/entity/cruise/store/cruise-list.store";
import {orderStore} from "@/entity/order/store/order.store";
import {createPassengerList} from "@/entity/order/ui/passenger-list";
import {createOrderTimeSelect} from "@/entity/order/ui/order-time.select";
import {createOrderFormModal} from "@/entity/order/ui/modal-window";
import {createOrderDatepicker} from "@/entity/order/ui/order-datepicker";
import {createOrderPrice} from "@/entity/order/ui/order-price";
import {createFormValidator} from "./validator";
import {createOrderData} from './form-data-factory';
import './style/index.css';
import {useActionModal} from "@/shared/ui/action-modal";
import {passengerTypeStore} from "@/entity/order/store/passenger-type.store";


export function createOrderForm({onValid, onShow} = {}) {
    const onValidCb = useCallback(onValid);

    const form = document.getElementById('buy-ticket');
    form.addEventListener('change', () => {
        reachYMGoal('InitiateCheckout');
    });

    const validator = createFormValidator({
        form,
        onValid: () => {
            const {passenger, fullname, email, phone} = createOrderData(form);
            if (validateCountChild(passenger)) {
                orderStore.setOrderContacts({fullname, email, phone});
                orderStore.updatePassengerList(passenger);
                return onValidCb.call();
            }
            return false;
        }
    });
    form.addEventListener('submit', function (event) {
        event.preventDefault();
        validator.validate();
    });
    const calculateCountPassengers = (passenger) => {
        let roomClasses = new Map();
        for (let [, item] of passenger.entries()) {
            const {metaTariffType, roomClass_id} = orderStore.getTariffById(item.tariff);
            if (!roomClasses.has(roomClass_id)) {
                roomClasses.set(roomClass_id, 0);
            }
            let currentPassengers = roomClasses.get(roomClass_id);
            if (passengerTypeStore.isAdult(metaTariffType)) {
                currentPassengers -= 1;
            }
            if (passengerTypeStore.isChild(metaTariffType)) {
                currentPassengers += 1;
            }
            roomClasses.set(roomClass_id, currentPassengers);
        }
        return roomClasses;
    };
    const validateCountChild = (passenger) => {
        let success = true;
        for (let [, item] of calculateCountPassengers(passenger).entries()) {
            if (item > 0) {
                useActionModal({
                    heading: "Ошибка",
                    text: 'Каждый ребенок до 2-х лет должен быть в сопровождении взрослого.'
                });
                success = false;
            }
        }
        return success;
    };
    form
        .querySelectorAll('.form-input__src--phone-number')
        .forEach((phoneInput) => createPhoneInputMask(phoneInput));
    const modal = createOrderFormModal({target: form, onShow});

    const datepicker = createOrderDatepicker(form);
    const directionSelect = createSelectMenuDark({
        select: form.querySelector('.form-input__src--select-direction'),
        onSelect: (direction) => {
            orderStore.setDirection(direction.value);
        }
    });
    const timeSelect = createOrderTimeSelect({
        select: form.querySelector('.form-input__src--select-time'),
        onSelect: (timeStamp) => {
            const resultCruiseList = cruiseListStore.getCruiseByFilter({
                directionId: orderStore.getDirection(),
                timeStamp,
                date: orderStore.getDate()
            });
            orderStore.setCruise(resultCruiseList[0]);
        }
    });
    const priceView = createOrderPrice();

    const passengerList = createPassengerList(
        {
            form,
            onTariffSelect: (passengerKey, tariffId) => {
                const price = orderStore.getTariffPriceById(tariffId);
                if (typeof price !== 'undefined') {
                    priceView.addPrice(passengerKey, price);
                }
            },
            onPassengerRemove: (passengerKey) => {
                priceView.removePrice(passengerKey);
                orderStore.setPassengerCount(orderStore.getPassengerCount() - 1);
                validator.update();
            },
            onPassengerAdd: (addedCount) => {
                validator.update();
                if (addedCount !== null) {
                    orderStore.setPassengerCount(orderStore.getPassengerCount() + addedCount);
                }
            }
        }
    );
    const renderPassenegers = () => passengerList.render(orderStore.getPassengerCount());
    form
        .querySelector('.action-btn--add-passenger')
        .addEventListener('click', () => {
            passengerList.addPassengerAction();
        });

    cruiseListStore.useEffect(() => {
        datepicker.updateNotDisabledDates(cruiseListStore.getDates())
    });
    orderStore.useDate(() => {
        const directionOptions = cruiseListStore.getDirectionOptionsByDate(orderStore.getDate());
        directionSelect.setOptions(directionOptions);
        datepicker.setDate(orderStore.getDate());
        timeSelect.clearValue().setOptions([]);
        passengerList.setupPassengerTariffs([]);
        setTimeout(() => {
            validator.reset()
        }, 100);
    });
    orderStore.useDirection(() => {
        const timeOptions = cruiseListStore.getTimeOptionsByDestIdAndDate(
            orderStore.getDirection(),
            orderStore.getDate()
        );
        directionSelect.selectOne(orderStore.getDirection());
        timeSelect.clearValue().setOptions(timeOptions);
        passengerList.setupPassengerTariffs([]);
        setTimeout(() => {
            validator.reset()
        }, 100);
    });
    const downloadCruiseTariffs = async () => {
        const id = orderStore.getCruiseId();
        if (id) {
            try {
                await orderStore.downloadCruiseTariffs(id);
            } catch (e) {
                console.log(e);
                useActionModal({
                    heading: 'Ошибка сети',
                    text: 'Не удалось загрузить тарифы, пожалуйста повторите позже',
                });
            }
        }
    };
    orderStore.useCruise(downloadCruiseTariffs);
    orderStore.useTariffList(() => {
        passengerList.setupPassengerTariffs(orderStore.getTariffOptions());
    });

    return {
        renderPassenegers,
        onValid: onValidCb.set,
        ...modal
    }
}
