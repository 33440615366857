import {orderStore} from "@/entity/order/store/order.store";

export const formDataFactory = (formEl) => {
    const form = new FormData(formEl);
    const result = new Map();
    const mapNumberIndexed = orderStore.getSeatMapNumberIndexed();
    for (let [key, value] of form.entries()) {
        if (value && key !== 'agree') {
            result.set(parseInt(key), mapNumberIndexed.get(value).id);
        }
    }
    return result;
};
