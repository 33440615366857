export const createOrderSummaryTemplate = (
    {
        price,
        fullname,
        email,
        phoneNumber,
        destination,
        startDate,
        startTime,
        destTime,
        passengersSlot
    }
) => `
    <li class="order-summary__group">
        <dl class="order-summary__group-list">
            <div class="order-summary__group-list-item">
                <dt class="order-summary__property">Направление:</dt>
                <dd class="order-summary__property-value">${destination}</dd>
            </div>
            <div class="order-summary__group-list-item">
                <dt class="order-summary__property">Дата отправления:</dt>
                <dd class="order-summary__property-value">${startDate}</dd>
            </div>
            <div class="order-summary__group-list-item">
                <dt class="order-summary__property">Время отправления</dt>
                <dd class="order-summary__property-value">${startTime}</dd>
            </div>
            <div class="order-summary__group-list-item">
                <dt class="order-summary__property">Время прибытия</dt>
                <dd class="order-summary__property-value">${destTime}</dd>
            </div>
        </dl>
    </li>
    <li class="order-summary__group">
        <h6 class="order-summary__group-header">Пассажиры</h6>
        ${passengersSlot}
    </li>
    <li class="order-summary__group">
        <dl class="order-summary__group-list">
            <div class="order-summary__group-list-item">
                <dt class="order-summary__property">Ф. И. О.:</dt>
                <dd class="order-summary__property-value">${fullname}</dd>
            </div>
            <div class="order-summary__group-list-item">
                <dt class="order-summary__property">Телефон:</dt>
                <dd class="order-summary__property-value">${phoneNumber}</dd>
            </div>
            <div class="order-summary__group-list-item">
                <dt class="order-summary__property">E-mail:</dt>
                <dd class="order-summary__property-value">${email}</dd>
            </div>
            <div class="order-summary__group-list-item">
                <dt class="order-summary__property order-summary__property--total">ИТОГО:</dt>
                <dd class="order-summary__property-value order-summary__property-value--total">${price} ₽</dd>
            </div>
        </dl>
    </li>
`;
