import {passengerTypeStore} from "@/entity/order/store/passenger-type.store";

const placeholderTemplate = () => `<span class="passenger-list__seat-placeholder">Выберите место</span>`;
const placeholderTemplateChild = () => `<span class="passenger-list__seat-placeholder">Без места</span>`;

export const seatListItemTemplate = ({index, key, name, tariff, isChild}) => {
    const template = `
        <div class="passenger-list__count">Пассажир ${index}</div>
        <span class="passenger-list__fio">${name}</span>
        <div class="passenger-list__tarif">
            <span class="passenger-list__tarif-placeholder">Тариф</span>
            <span class="passenger-list__tarif-value">${tariff}</span>
        </div>
        <label class="passenger-list__seat pristine-input">
            ${
                isChild ?
                `<input style="display: none" type="text" name="${key}" disabled/>` :
                `<input style="display: none" type="text" name="${key}" required 
                    data-pristine-required-message="Необходимо выбрать место"
                />`
            }
            <div class="passenger-list__seat-value-wrapper">
                ${isChild ? placeholderTemplateChild() : placeholderTemplate()}
            </div>
        </label>
        ${isChild ? '' : `<div class="prtistine-input__error"></div>`}
`;

    const wrapper = document.createElement('li');
    wrapper.classList.add('passenger-list__item');
    wrapper.classList.add('prtistine-input-wrapper');
    if (isChild) {
        wrapper.classList.add('passenger-list__item-disabled');
        wrapper.readOnly = true;
    }
    wrapper.innerHTML = template;
    return wrapper;
};
