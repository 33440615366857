import {createBookingFlow} from "@/feature/order-flow/booking-flow";
import {cruiseListStore} from "@/entity/cruise/store/cruise-list.store";
import {orderStore} from "@/entity/order/store/order.store";
import {createPaymentFlow} from "@/feature/order-flow/payment-flow";
import {motorshipsStore} from "@/entity/ship/store/motorships.store";

export function createOrderFlow({isPassengersRender = null} = {}) {
    const bookingFlow = createBookingFlow({
        isPassengersRender
    });
    const paymentFlow = createPaymentFlow();

    bookingFlow.onComplete(paymentFlow.start);
    paymentFlow.onDecline(bookingFlow.startConversely);

    document
        .querySelectorAll('.buy-btn-desktop, .btn--call-preorder, .footer__info-btn button, .timetable-buy-ticket-btn')
        .forEach((el) => {
            el.addEventListener('click', () => {
                if(paymentFlow.isPaymentRetry()){
                    paymentFlow.start();
                }else{
                    bookingFlow.start();
                }
            });
        });

    setTimeout(async () => {
        await motorshipsStore.downloadMotorships();
        await cruiseListStore.downloadCruises();
        const nearestCruiseDate = cruiseListStore.getNearestDate();
        if(nearestCruiseDate !== null){
            orderStore.setDate(nearestCruiseDate);
        }
    }, 0);
}
