import {createPopper} from '@popperjs/core';
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import {subDays} from "date-fns";
import {useCallback} from "@/shared/useCallback";
import {dateToDateKey, formatDate} from "@/shared/air-datepicker";
import './styles.css';

export const createDatepicker = (
    {
        el,
        onSelect,
        onShow,
        onHide,
        availableDates = [],
        placeholder = true,
        offset = [0, 10]
    } = {}
) => {
    const onSelectCb = useCallback(onSelect);
    const onShowCb = useCallback(onShow);
    const onHideCb = useCallback(onHide);
    let notDisabledDates = availableDates;
    const today = new Date();
    if (placeholder === true) {
        el.placeholder = formatDate(today);
    } else if (placeholder !== false) {
        el.placeholder = placeholder
    }
    const datepicker = new AirDatepicker(el, {
        showEvent: 'datepicker-show',
        minDate: subDays(today, 1),
        dateFormat: 'dd/MM/yyyy',
        autoClose: true,
        onSelect: ({date}) => {
            onSelectCb.call(date);
            el.dispatchEvent(new Event('input'));
        },
        onRenderCell({date}) {
            if (notDisabledDates.includes(dateToDateKey(date, false)) !== true) {
                return {
                    disabled: true,
                    classes: 'disabled-date',
                };
            }
        },
        onShow: () => {
            onShowCb.call();
        },
        onHide: () => {
            onHideCb.call();
        },
        position({$datepicker, $target, $pointer, done}) {
            let popper = createPopper($target, $datepicker, {
                placement: 'top',
                modifiers: [
                    {
                        name: 'flip',
                        options: {
                            allowedAutoPlacements: ['top', 'bottom'],
                            padding: {
                                top: 500
                            },
                        },
                    },
                    {
                        name: 'offset',
                        options: {
                            offset
                        }
                    },
                    {
                        name: 'arrow',
                        options: {
                            element: $pointer
                        }
                    }
                ]
            });
            popper.forceUpdate();
            return function () {
                popper.destroy();
                done();
            }
        }
    });
    const updateNotDisabledDates = (dates) => {
        notDisabledDates = dates;
        datepicker.setCurrentView('days');
    };
    const toggleAction = () => {
        if (datepicker.visible) {
            datepicker.hide();
        } else {
            datepicker.show();
            el.focus();
        }
    };
    el.addEventListener('click', () => {
        toggleAction();
    });

    return {
        onSelect: onSelectCb.set,
        onShow: onShowCb.set,
        onHide: onHideCb.set,
        updateNotDisabledDates,
        setDate: (date) => {
            datepicker.selectDate(date, {silent: true})
        },
        toggleAction,
    };
};
