import {useCallback} from "@/shared/useCallback";
import {createModalWindow} from "@/shared/ui/modal-window";
import {orderStore} from "@/entity/order/store/order.store";
import {createPassengerSeatList} from "@/entity/order/ui/passenger-seat-list";
import {createSelectSeatLegend} from "@/entity/order/ui/select-seat-legend";
import {createShipView} from "@/entity/order/ui/ship-view";
import {formDataFactory} from "./form-data-factory";
import {createFormValidator} from "./validator";
import './styles.css';

const createModal = ({form, onClose, onShow}) => {
    const modal = createModalWindow({
        target: form, onClose, onShow
    });

    form.querySelector('.form-popup__close-btn').addEventListener('click', () => {
        modal.close();
    });
    return modal;
};
const createDestinationName = (form) => {
    const destinationView = (form ?? document).querySelector('.trip-settig__item--destination');
    const destinationName = destinationView.querySelector('.trip-settig__item-value');
    const setName = (value) => {
        destinationName.textContent = value;
    };

    return {
        setName,
    };
};
const createMotorshipName = (form) => {
    const motorshipView = (form ?? document).querySelector('.trip-settig__item--ship');
    const motorshipName = motorshipView.querySelector('.trip-settig__item-value');
    const setName = (value) => {
        motorshipName.textContent = value;
    };

    return {
        setName,
    };
};

export function createSeatSelectForm({onClose = null, onShow = null, onValid = null, onBackward = null} = {}) {
    const onBackwardCb = useCallback(onBackward);
    const onValidCb = useCallback(onValid);

    const form = document.getElementById('buy-ticket--select-seat');
    const destinationView = createDestinationName(form);
    const motorshipView = createMotorshipName(form);
    const seatLegend = createSelectSeatLegend();
    const modal = createModal({form, onClose, onShow});
    const validator = createFormValidator({
        form,
        onValid: () => {
            orderStore.setPassengersSeats(formDataFactory(form));
            onValidCb.call();
        }
    });
    form.addEventListener('submit', (event) => {
        event.preventDefault();
        validator.validate();
        passengerList.setupNext();
    });
    form.querySelector('.control-panel__action-btn--backward').addEventListener('click', onBackwardCb.call);

    const shipView = createShipView();
    const passengerList = createPassengerSeatList({
        list: form.querySelector('.passenger-list'),
        onSelectionStart: (roomClassId) => {
            shipView.enableByRoomClass(roomClassId);
            shipView.enable();
        }
    });
    shipView.onSelect(({dto, setSeatViewSelected}) => {
        const selected = passengerList.selectCurrent(dto);
        if (selected !== null) {
            if (selected.prevSeat !== null) {
                shipView.cleanupSelected(selected.prevSeat)
            }
            setSeatViewSelected(selected.name);
            orderStore.setPassengerSeat(selected.key, selected.seatNumber);
        }
        passengerList.setupNext();
    });
    shipView.onSelectedClick(({dto}) => {
        passengerList.setupBySeatNumber(dto.seat);
    });

    orderStore.useCruise(() => {
        const directionName = orderStore.getDirectionName();
        if (directionName) {
            destinationView.setName(directionName);
        }
        const motorshipName = orderStore.getMotorshipName();
        if (motorshipName) {
            motorshipView.setName(motorshipName);
        }
    });
    orderStore.usePassengerList(() => {
        shipView.resetSeats();// Сброс мест необходимо проводить до инициализации списка пассажиров, и его последующего события onSelectionStart
        const passengerListData = orderStore.getPassengerListFilled();
        passengerList.initList(passengerListData);
        validator.update();
        setTimeout(() => {
            for (let {seatNumber, name} of passengerList.getSeatViewData()) {
                if(seatNumber){
                    const seatView = shipView.getSeatViewByNumber(seatNumber);
                    if (seatView !== null) {
                        seatView
                            .setDefault()
                            .setSelected()
                            .setText(name)
                    }
                }
            }
        }, 0);
    });
    orderStore.useDeck(() => {
        seatLegend.init(orderStore.getRoomClasses());
        shipView
            .setScheme(orderStore.getDeckSceheme())
            .initPlaces(orderStore.getSeatMapNumberIndexed())
    });

    return {
        ...modal,
        onValid: validator.onValid
    };
}
