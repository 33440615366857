export const selectedSeatTemplate = (row, column) => `
<span class="passenger-list__seat-row">
    <span class="passenger-list__seat-text">Ряд</span>
    <span class="passenger-list__seat-value">${column}</span>
</span>
<span class="passenger-list__seat-number">
    <span class="passenger-list__seat-text">Место</span>
    <span class="passenger-list__seat-value">${row}</span>
</span>
`;
