import {useCallback} from "@/shared/useCallback";
import {createDateInputMask} from "@/shared/ui/date-input-mask";
import {createSelectMenuDark} from "@/shared/ui/select-menu-dark";
import {orderStore} from "@/entity/order/store/order.store";
import {sexStore} from "@/entity/order/store/sex.store";
import {documentStore} from "@/entity/order/store/document.store";
import {nationalityStore} from "@/entity/order/store/nationality.store";
import './style.css';

let index = 0;
const createTemplte = ({onPassengerRemove}) => {
    const onPassengerRemoveCb = useCallback(onPassengerRemove);
    const wrapper = document.createElement('div');
    wrapper.classList.add('form-popup__group', 'form-popup__group--passenger');
    wrapper.innerHTML = `
        <div class="form-popup__group-label">
            Пассажир
            ${index === 0 ? '' : `<button type="button" class="form-popup__group-close"></button>`}
        </div>
        <div class="form-popup__group-grid">
            <div class="form-popup__group-grid-row">
                <label class="form-input">
                    <span class="form-input__control form-input__control--outlined cursor-default">
                        <input class="form-input__src px-[15px]" name="passenger[${index}][lastname]" type="text"
                               data-pristine-cirilic
                               placeholder="Фамилия" required
                               data-pristine-required-message="Введите фамилию"
                        />
                    </span>
                    <span class="form-input__error"></span>
                </label>
                <label class="form-input">
                    <span class="form-input__control form-input__control--outlined cursor-default">
                        <input class="form-input__src px-[15px]" name="passenger[${index}][firstname]" type="text"
                               data-pristine-cirilic
                               placeholder="Имя" required
                               data-pristine-required-message="Введите имя"               
                        />
                    </span>
                    <span class="form-input__error"></span>
                </label>
                <label class="form-input">
                    <span class="form-input__control form-input__control--outlined cursor-default">
                        <input class="form-input__src px-[15px]" name="passenger[${index}][middlename]" type="text"
                               data-pristine-cirilic
                               placeholder="Отчество"/>
                    </span>
                    <span class="form-input__error"></span>
                </label>
            </div>
            <label class="form-input form-input--sex">
                <span class="cursor-pointer form-input__control form-input__control--dropdown px-[15px]">
                    <select class="form-input__src form-input__src--select form-input__src--select-sex" name="passenger[${index}][sex]"
                        required
                        data-placeholder="Выберите пол"
                        data-pristine-required-message="Выберите пол"
                    ></select>
                </span>
                <span class="form-input__error"></span>
            </label>
            <label class="form-input form-input--bday">
                <span class="form-input__control form-input__control--outlined cursor-default">
                    <input class="form-input__src form-input__src--birthday px-[15px]" name="passenger[${index}][birthday]" type="text"
                           placeholder="Дата рождения"
                           required
                           data-pristine-inputmask-datetime
                           data-pristine-required-message="Введите дату рождения"
                    />
                </span>
                <span class="form-input__error"></span>
            </label>
            <label class="form-input form-input--nationality">
                <span class="cursor-pointer form-input__control form-input__control--dropdown px-[15px]">
                    <select class="form-input__src form-input__src--select form-input__src--select-nationality" name="passenger[${index}][nationality]"
                            required
                            data-placeholder="Выберите гражданство"
                            data-pristine-required-message="Выберите гражданство"
                    ></select>
                </span>
                <span class="form-input__error"></span>
            </label>
            <label class="form-input form-input--bplace">
                <span class="form-input__control form-input__control--outlined cursor-default">
                    <input class="form-input__src px-[15px]" name="passenger[${index}][birth_place]" type="text" placeholder="Место рождения"
                       required
                       data-pristine-required-message="Введите место рождения"
                    />
                </span>
                <span class="form-input__error"></span>
            </label>
            <label class="form-input form-input--doc-type">
                <span class="cursor-pointer form-input__control form-input__control--dropdown px-[15px]">
                    <select class="form-input__src form-input__src--select form-input__src--select-document" name="passenger[${index}][doc]"
                        required
                        data-placeholder="Выберите документ"
                        data-pristine-required-message="Выберите тип документа"
                    ></select>
                </span>
                <span class="form-input__error"></span>
            </label>
            <div class="form-popup__group-grid">
                <label class="form-input form-input--doc-series">
                    <span class="form-input__control form-input__control--outlined cursor-default">
                        <input class="form-input__src form-input__src--doc-series px-[15px]"
                            name="passenger[${index}][doc_series]"
                            type="text"
                            placeholder="Серия"
                            required
                            data-pristine-required-message="Введите серию документа"
                        />
                    </span>
                    <span class="form-input__error"></span>
                </label>
                <label class="form-input form-input--doc-number">
                    <span class="form-input__control form-input__control--outlined cursor-default">
                        <input class="form-input__src form-input__src--doc-number px-[15px]" name="passenger[${index}][doc_number]" type="text" placeholder="Номер"
                                required
                               data-pristine-required-message="Введите номер документа"
                        />
                    </span>
                    <span class="form-input__error"></span>
                </label>
            </div>
            <label class="form-input form-input--tariff">
                <span class="cursor-pointer form-input__control form-input__control--dropdown px-[15px]">
                    <select class="form-input__src form-input__src--select form-input__src--select-tariff" name="passenger[${index}][tariff]"
                            required
                            data-placeholder="Выберите тариф"
                            data-pristine-required-message="Выберите тариф"
                    ></select>
                </span>
                <span class="form-input__error"></span>
            </label>
        </div>
    `;
    const passengerKey = Number(index);
    index++;
    wrapper.querySelector('.form-popup__group-close')?.addEventListener('click', () => {
        wrapper.remove();
        onPassengerRemoveCb.call(passengerKey);
    });
    return [passengerKey, wrapper];
};
const createDocumentInput = (inputSrc, mask) => {
    let controller = null;
    const setupMask = () => {
        Inputmask(mask, {clearIncomplete: true}).mask(inputSrc);
        let updated = null;
        controller = new AbortController();
        inputSrc.addEventListener('input', (e) => {
            if (updated === null) {
                updated = setTimeout(() => {
                    inputSrc.dispatchEvent(e)
                }, 0);
            } else {
                updated = null;
            }
        }, {signal: controller.signal});
    };
    const cleanupMask = () => {
        if (controller !== null) {
            Inputmask.remove(inputSrc);
            controller.abort();
            controller = null;
        }
    };
    return {
        setupMask,
        cleanupMask
    };
};
export function createFormPassengerPerson({onTariffSelect = null, onPassengerRemove = null} = {}) {
    const onTariffSelectCb = useCallback(onTariffSelect);

    const [key, template] = createTemplte({onPassengerRemove});
    const documentSeries = createDocumentInput(
        template.querySelector('.form-input__src--doc-series'),
        '9999'
    );
    const documentNumber = createDocumentInput(
        template.querySelector('.form-input__src--doc-number'),
        '999999'
    );

    createSelectMenuDark({
        select: template.querySelector('.form-input__src--select-nationality'),
        options: nationalityStore.options,
        showSearch: true
    });
    createSelectMenuDark({
        select: template.querySelector('.form-input__src--select-sex'),
        options: sexStore.options,
    });
    createSelectMenuDark({
        select: template.querySelector('.form-input__src--select-document'),
        options: documentStore.options,
        onSelect: (option) => {
            if (documentStore.isPassportRF(option.value)) {
                documentSeries.setupMask();
                documentNumber.setupMask();
            } else {
                documentSeries.cleanupMask();
                documentNumber.cleanupMask();
            }
        }
    });
    const tariffSelect = createSelectMenuDark({
        select: template.querySelector('.form-input__src--select-tariff'),
        options: orderStore.getTariffOptions(),
        onSelect: (option) => {
            onTariffSelectCb.call(key, option.value);
        }
    });
    template
        .querySelectorAll('.form-input__src--birthday')
        .forEach((dateInput) => {
            createDateInputMask(dateInput);
        });

    return {
        template,
        resetSelectedTariff: () => tariffSelect.clearValue(),
        setupTariffOptions: (optList) => tariffSelect.setOptions(optList),
        onTariffSelect: onTariffSelectCb.set,
    };
}
