import {createSelectMenu} from "@/shared/ui/select-menu";
import {createDropdownToggle} from "@/shared/ui/dropdown-toggle";

export function createSelectMenuDark({select, onSelect, options = [], showSearch} = {}) {
    const dropdownToggle = createDropdownToggle(
        select.closest('.form-input__control--dropdown'),
        {
            cn: 'form-input__control--dropdown-shown',
            onClick: (evt) => {
                selectMenu.toggleAction(evt);
            }
        }
    );
    const selectMenu = createSelectMenu({
        select,
        onSelect,
        options,
        showSearch,
        relative: true,
        onShow: dropdownToggle.showOpened,
        onHide: dropdownToggle.showClosed
    });
    return {
        setOptions: selectMenu.setOptions,
        selectOne: selectMenu.selectOne,
        clearValue: () => {
            selectMenu.selectOne(null);
        }
    };
}
