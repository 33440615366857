export const createPassengerSummaryTemplate = (
    {
        fullName,
        sex,
        birthday,
        birthplace,
        documentType,
        documentNumber,
        tariff,
        seatNumber,
        seatRow,
        citizen
    }
) => `
<dl class="order-summary__group-list">
    <div class="order-summary__group-list-item">
        <dt class="order-summary__property">Ф. И. О.</dt>
        <dd class="order-summary__property-value">${fullName}</dd>
    </div>
    <div class="order-summary__group-list-item">
        <dt class="order-summary__property">Пол</dt>
        <dd class="order-summary__property-value">${sex}</dd>
    </div>
    <div class="order-summary__group-list-item">
        <dt class="order-summary__property">Дата рождения:</dt>
        <dd class="order-summary__property-value">${birthday}</dd>
    </div>
    <div class="order-summary__group-list-item">
        <dt class="order-summary__property">Гражданство</dt>
        <dd class="order-summary__property-value">${citizen}</dd>
    </div>
    <div class="order-summary__group-list-item">
        <dt class="order-summary__property">Место рождения</dt>
        <dd class="order-summary__property-value">${birthplace}</dd>
    </div>
    <div class="order-summary__group-list-item">
        <dt class="order-summary__property">${documentType}</dt>
        <dd class="order-summary__property-value">${documentNumber}</dd>
    </div>
    <div class="order-summary__group-list-item">
        <dt class="order-summary__property">Тариф</dt>
        <dd class="order-summary__property-value">${tariff}</dd>
    </div>
    ${
        seatRow ?
        `<div class="order-summary__group-list-item">
            <dt class="order-summary__property">Ряд</dt>
            <dd class="order-summary__property-value">${seatRow}</dd>
        </div>` :
        ''
    }
    <div class="order-summary__group-list-item">
        <dt class="order-summary__property">Место</dt>
        <dd class="order-summary__property-value">${seatNumber ? seatNumber : 'Без места'}</dd>
    </div>
</dl>
`;
