import {useCallback} from "@/shared/useCallback";
import {createSeatView} from "./seat-view";

const UNSELECTABLE_CLASS = 'prevent-select';
const SEAT_PLACE_REGEXP = /place_?(\d*)/;
const PLACE_WRAPPER_SELECTOR = 'g[id^="place"]';

const isNodePlaceTarget = (node) => {
    let result = null;
    if (node && node.id && SEAT_PLACE_REGEXP.test(node.id)) {
        result = SEAT_PLACE_REGEXP.exec(node.id)[1];
    }
    return result !== null;
};
const parsePlaceWrapperNode = (node) => {
    let result = null;
    if (isNodePlaceTarget(node)) {
        result = node;
    } else {
        const placeWrapper = node.closest(PLACE_WRAPPER_SELECTOR);
        if (isNodePlaceTarget(placeWrapper)) {
            result = placeWrapper;
        }
    }
    return result;
};

export const createShipView = ({onSelect, onSelectedClick} = {}) => {
    const onSelectCb = useCallback(onSelect);
    const onSelectedClickCb = useCallback(onSelectedClick);
    const shipView = () => document.querySelector('.select-seat__seat-viewport > svg');
    let seatClickController = null;
    const disable = () => {
        if (seatClickController !== null) {
            seatClickController.abort();
        }
        shipView().classList.add(UNSELECTABLE_CLASS);
    };
    const enableSelectHandler = () => {
        seatClickController = new AbortController();
        shipView().classList.remove(UNSELECTABLE_CLASS);
    };

    let roomClassMap = new Map();
    let seatViewMap = new Map();
    const shipViewport = document.querySelector('.select-seat__seat-viewport');
    const usePlaces = (cb) => shipViewport.querySelectorAll(PLACE_WRAPPER_SELECTOR).forEach(cb);
    const setScheme = function (scheme) {
        shipViewport.innerHTML = scheme;
        return this;
    };
    const getSeatViewByNumber = (seatNumber) => seatViewMap.get(
        shipView().querySelector(`#rf-${seatNumber}`)?.parentElement
    ) ?? null;
    const initPlaces = function (seatMapNumberIndexed) {
        seatViewMap = new Map();
        roomClassMap = new Map();
        usePlaces((placeWrapper) => {
            const seatView = createSeatView(placeWrapper);
            const seatData = seatMapNumberIndexed.get(seatView.getSeatNumber());
            seatView.setDisabled();
            if (seatData && seatData['availability'] === 1) {
                seatView.setDefault();
                const roomClass = seatData['roomClass'];
                if (roomClassMap.has(roomClass)) {
                    const list = roomClassMap.get(roomClass);
                    list.push(seatView);
                    roomClassMap.set(roomClass, list);
                } else {
                    roomClassMap.set(roomClass, [seatView]);
                }
                seatViewMap.set(placeWrapper, seatView);
            }
        });
        return this;
    };
    const resetSeats = function () {
        for (const seatView of seatViewMap.values()) {
            seatView.setDefault();
        }
        return this;
    };
    const disableSeats = function () {
        for (const seatView of seatViewMap.values()) {
            if (!seatView.isSelected()) {
                seatView.setDefault().setDisabled();
            }
        }
        return this;
    };

    const enableByRoomClass = (roomClassId) => {
        for (let [roomClassIdKey, roomClassSeatViews] of roomClassMap.entries()) {
            if (roomClassIdKey === roomClassId) {
                for (let roomClassSeatView of roomClassSeatViews) {
                    if (!roomClassSeatView.isSelected()) {
                        roomClassSeatView.setDefault();
                    }
                }
            } else {
                for (let roomClassSeatView of roomClassSeatViews) {
                    if (!roomClassSeatView.isSelected()) {
                        roomClassSeatView.setDefault().setDisabled();
                    }
                }
            }
        }
    };

    const enable = () => {
        disable();
        enableSelectHandler();
        shipView().addEventListener('click', async (event) => {
            const placeWrapper = parsePlaceWrapperNode(event.target);
            if (placeWrapper) {
                const seatView = seatViewMap.get(placeWrapper);
                const seat = seatView.getSeatNumber();
                const [row, column] = seatView.getSeatPosition();
                const setSeatViewSelected = (text = null) => {
                    seatView.setSelected().setText(text);
                };

                const selectedCbApi = {
                    dto: {row, column, seat},
                    setSeatViewSelected
                };
                if (seatView.isSelected()) {
                    await onSelectedClickCb.call(selectedCbApi);
                } else {
                    await onSelectCb.call(selectedCbApi);
                }
            }
        }, {signal: seatClickController.signal});
    };
    const cleanupSelected = (seatNumber) => {
        const seat = getSeatViewByNumber(seatNumber);
        if (seat) {
            seat.setDefault()
        }
    };

    enable();
    return {
        enable,
        disable,
        setScheme,
        initPlaces,
        resetSeats,
        disableSeats,
        cleanupSelected,
        enableByRoomClass,
        getSeatViewByNumber,
        onSelect: onSelectCb.set,
        onSelectedClick: onSelectedClickCb.set
    }
};
