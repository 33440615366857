import {createValidator} from "@/shared/validator-interface";
import {usePristine} from "@/shared/usePristine";

export function createFormValidator({form, onValid}) {
    let validatorInstance = null;
    const factory = () => {
        validatorInstance = usePristine(form, {
            classTo: 'form-input',
            errorClass: 'form-input--error',
            errorTextParent: 'form-input__error'
        });
        return validatorInstance;
    };
    return createValidator({
        factory,
        onValid,
        onInvalid: () => {
            let input = validatorInstance.fields.filter((field) => field.errors.length > 0)[0]?.input;
            if (input.nodeName === 'SELECT') {
                input = input.parentElement;
            }
            input.scrollIntoView({ behavior: 'smooth' });
        }
    });
}
