import {useCallback} from "@/shared/useCallback";
import {createFormPassengerPerson} from "./passenger-preson";

export const createPassengerList = ({form, onTariffSelect, onPassengerRemove, onPassengerAdd}={}) => {
    const onPassengerAddCb = useCallback(onPassengerAdd);
    const passengers = [];

    const appendPassengerTemplate = (passengerTemplate) => {
        const passengersList = form.querySelectorAll('.form-popup__group--passenger');
        passengersList.item(passengersList.length - 1).after(passengerTemplate);
    };
    const addPassengerAction = () => {
        const passenger = createFormPassengerPerson({onTariffSelect, onPassengerRemove});
        appendPassengerTemplate(passenger.template);
        passengers.push(passenger);
        onPassengerAddCb.call(1);
    };
    const render = function (count) {
        const placeholder = form.querySelector('.form-popup__group--main-config');
        for (let i = 0; i < count; i++) {
            passengers.push(createFormPassengerPerson({onTariffSelect, onPassengerRemove}));
        }
        placeholder.after(...(passengers.map(passenger => passenger.template)));
        onPassengerAddCb.call(null);
        return this;
    };

    const cleanup = () => {
        form.querySelectorAll('.form-popup__group--passenger').forEach(
            (el) => el.remove()
        );
    };
    const setupPassengerTariffs = (tariffs=[]) => {
        passengers.forEach((passenger) => {
            passenger.resetSelectedTariff();
            passenger.setupTariffOptions(tariffs);
        })
    };

    return {
        render,
        cleanup,
        addPassengerAction,
        setupPassengerTariffs,
    }
};
