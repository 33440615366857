import {dateFromuserInput} from "@/shared/air-datepicker";

const passengerId = /passenger\[(\d+)]/;
const passengerInfoKey = /passenger\[\d+]\[(.+)]/;
const PARSE_INT_KEYS = ['tariff', 'sex', 'document_type', 'nationality'];
const PARSE_DATE_KEY = ['birthday'];
export const createOrderData = (formEl) => {
    const form = new FormData(formEl);
    const formDataObj = {};
    const passengerMap = new Map();
    for (let [key, value] of form.entries()) {
        if (passengerId.test(key)) {
            const keyID = parseInt(passengerId.exec(key)[1]);
            if (!passengerMap.has(keyID)) {
                passengerMap.set(keyID, {});
            }
            const passenger = passengerMap.get(keyID);
            const passengerKey = passengerInfoKey.exec(key)[1];
            if(PARSE_INT_KEYS.includes(passengerKey)){
                passenger[passengerKey] = parseInt(value) ?? null;
            } else if(PARSE_DATE_KEY.includes(passengerKey)) {
                passenger[passengerKey] = dateFromuserInput(value);
            } else {
                passenger[passengerKey] = value;
            }
            passengerMap.set(keyID, passenger);
        } else {
            formDataObj[key] = value;
        }
    }
    formDataObj['passenger'] = passengerMap;
    return formDataObj;
};
