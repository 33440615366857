import {createDatepicker} from "@/shared/ui/datepicker";
import {orderStore} from "@/entity/order/store/order.store";
import {createDropdownToggle} from "@/shared/ui/dropdown-toggle";

export function createOrderDatepicker(form) {
    const el = form.querySelector('.form-input__src--datepicker');
    const dropdownToggle = createDropdownToggle(
        el.closest('.form-input__control--dropdown'),
        {
            cn: 'form-input__control--dropdown-shown',
            reventMousedown: true
        }
    );
    const datepicker = createDatepicker(
        {
            el,
            onShow: dropdownToggle.showOpened,
            onHide: dropdownToggle.showClosed,
            placeholder: false,
            onSelect: orderStore.setDate
        }
    );
    return datepicker;
}
