import {useCallback} from "@/shared/useCallback";
import {selectedSeatTemplate} from "./selected-seat.template";
import {seatListItemTemplate} from "./seat-list-item.template";
import {useSignal} from "@/shared/useSignal";
import {passengerTypeStore} from "@/entity/order/store/passenger-type.store";

const CLASS_FOCUSED = 'passenger-list__seat--focused';

function createSelectSeatControl({index, key, name, name_short, tariff, tariffType, roomClass}) {
    const isChild = passengerTypeStore.isChild(tariffType);
    const template = seatListItemTemplate({index, key, name, tariff, isChild});
    const selectedView = template.querySelector('.passenger-list__seat-value-wrapper');
    const seatValueView = template.querySelector('.passenger-list__seat');
    const selectedValue = template.querySelector('input');
    let seatNumber = null;
    const setSelected = (row, column, seat) => {
        seatNumber = seat;
        selectedView.innerHTML = selectedSeatTemplate(row, column);
        selectedValue.value = seat;
        selectedValue.dispatchEvent(new Event('input'));
    };
    const cleanupFocus = () => {
        seatValueView.classList.remove(CLASS_FOCUSED);
    };
    const setupFocus = function () {
        seatValueView.classList.add(CLASS_FOCUSED);
        return this;
    };
    const getTarifType = () => tariffType;
    const isSelected = () => !!selectedValue.value;
    const getName = () => name;
    const getKey = () => key;
    const getNameShort = () => name_short;
    const getSeatNumber = () => seatNumber;
    const setupClick = (cb) => {
        template.addEventListener('click', () => {
            if (typeof cb === 'function') {
                cb.call(null);
            }
        })
    };
    return {
        setupClick,
        getTarifType,
        getName,
        getKey,
        getNameShort,
        isSelected,
        setSelected,
        setupFocus,
        cleanupFocus,
        getSeatNumber,
        getTemplate: () => template,
        getRoomClass: () => roomClass
    };
}

export function createPassengerSeatList(
    {
        list,
        onAllSelected = null,
        onSelectionStart = null,
    } = {}
) {
    const onAllSelectedCb = useCallback(onAllSelected);
    const onSelectionStartCb = useCallback(onSelectionStart);

    const [currentSelect, useCurrentSelect] = useSignal(null);
    let passengersControlMap = new Map();
    const getCurrent = () => passengersControlMap.get(currentSelect.value);
    const setupNext = () => {
        if (getCurrent()?.isSelected()) {
            for (let passenger of list.children) {
                if (
                    !passengersControlMap.get(passenger).isSelected() &&
                    !passengerTypeStore.isChild(passengersControlMap.get(passenger).getTarifType())
                ) {
                    getCurrent().cleanupFocus();
                    currentSelect.value = passenger;
                    break;
                }
            }
        }
    };

    const setupBySeatNumber = (seatNumber) => {
        for (let seatControl of passengersControlMap.values()) {
            if (seatNumber === seatControl.getSeatNumber()) {
                getCurrent().cleanupFocus();
                currentSelect.value = seatControl.getTemplate();
                break;
            }
        }
    };
    const selectCurrent = ({row, column, seat}) => {
        let result = null;
        const current = getCurrent();
        if (current) {
            const prevSeat = current.getSeatNumber();
            current.setSelected(column, row, seat);
            result = {
                name: current.getNameShort(),
                seatNumber: current.getSeatNumber(),
                key: current.getKey(),
                prevSeat
            };
        }
        return result;
    };

    const initList = (passengerMap) => {
        list.innerHTML = '';
        currentSelect.value = null;
        passengersControlMap = new Map();
        let index = 1;
        for (let [key, {firstname, lastname, middlename, tariff, seat}] of passengerMap.entries()) {
            const firstNameChar = firstname?.charAt(0)?.toUpperCase();
            const midNameChar = middlename?.charAt(0)?.toUpperCase();
            const lastNameChar = lastname?.charAt(0)?.toUpperCase();
            const seatControl = createSelectSeatControl({
                index,
                key,
                name: `${lastname} ${firstNameChar ? `${firstNameChar}.` : ''} ${midNameChar ? `${midNameChar}.` : ''}`,
                name_short: `${lastNameChar ? `${lastNameChar}.` : ''}${firstNameChar ? `${firstNameChar}.` : ''}`,
                tariff: tariff?.label,
                tariffType: tariff?.metaTariffType,
                roomClass: tariff?.roomClass_id
            });
            if (!passengerTypeStore.isChild(seatControl.getTarifType())) {
                seatControl.setupClick(() => {
                    currentSelect.value = seatControl.getTemplate()
                });

                if (seat?.number) {
                    const [column, row] = seat.number.split('-');
                    seatControl.setSelected(row, column, seat.number);
                }
            }
            passengersControlMap.set(seatControl.getTemplate(), seatControl);
            list.append(seatControl.getTemplate());
            if (!passengerTypeStore.isChild(seatControl.getTarifType())) {
                if (currentSelect.value === null) {
                    currentSelect.value = seatControl.getTemplate();
                }
            }
            index++;
        }
        setupNext();
    };

    useCurrentSelect(() => {
        if (currentSelect.value && passengerTypeStore.isChild(getCurrent().getTarifType())) {
            return;
        }
        for (let [, templateControl] of passengersControlMap.entries()) {
            templateControl.cleanupFocus()
        }
        if (currentSelect.value === null) {
            onAllSelectedCb.call();
        } else {
            onSelectionStartCb.call(
                getCurrent().setupFocus().getRoomClass()
            );
        }
    });
    const getSeatViewData = () => Array
        .from(passengersControlMap.values())
        .map(
            (passenger) => ({
                name: passenger.getNameShort(),
                seatNumber: passenger.getSeatNumber(),
            })
        );

    return {
        onAllSelected: onAllSelectedCb.set,
        onSelectionStart: onSelectionStartCb.set,
        initList,
        selectCurrent,
        setupNext,
        getSeatViewData,
        setupBySeatNumber,
    }
}
