import {createSelectMenu} from "@/shared/ui/select-menu";
import {useCallback} from "@/shared/useCallback";
import {timestampToTime} from "@/shared/air-datepicker";
import {createDropdownToggle} from "@/shared/ui/dropdown-toggle";
import {orderStore} from "@/entity/order/store/order.store";

export const createOrderTimeSelect = ({select, onSelect}) => {
    const selectCb = useCallback(onSelect);

    const dropdownToggle = createDropdownToggle(
        select.closest('.form-input__control--dropdown'),
        {
            cn: 'form-input__control--dropdown-shown',
            onClick: (evt) => {
                selectMenu.toggleAction(evt);
            }
        }
    );
    const selectMenu = createSelectMenu({
        select,
        onSelect: async (timeStart) => {
            setArrivalTime(timestampToTime(timeStart.value.arrival));
            await selectCb.call(timeStart.value.departure);
            const motorshipAliase = orderStore.getMotorshipAlias();
            if (motorshipAliase) {
                const shipIcons = document.getElementsByClassName('ship-icon');
                for (const ship of shipIcons) {
                    ship.dataset.alias = motorshipAliase;
                }
            }
        },
        relative: true,
        onShow: dropdownToggle.showOpened,
        onHide: dropdownToggle.showClosed
    });

    const arrivalTimePlaceholder = document.querySelector('.form-input__control--arrival-time .form-input__placeholder');
    const setArrivalTime = (time) => {
        arrivalTimePlaceholder.textContent = time;
    };
    return {
        setOptions: selectMenu.setOptions,
        clearValue: function () {
            selectMenu.selectOne(null);
            arrivalTimePlaceholder.textContent = '';
            const shipIcons = document.getElementsByClassName('ship-icon');
            for (const ship of shipIcons) {
                delete ship.dataset.alias;
            }
            return this;
        }
    };
};
