import {createValidator} from "@/shared/validator-interface";
import {usePristine} from "@/shared/usePristine";

export function createFormValidator({form, onValid}) {
    let validatorInstance = null;
    const factory = () => {
        validatorInstance = usePristine(form, {
            classTo: 'prtistine-input-wrapper',
            errorClass: 'pristine-input--error',
            errorTextParent: 'prtistine-input__error'
        });
        return validatorInstance;
    };
    return createValidator({
        factory,
        onValid,
        onInvalid: () => {
            const input = validatorInstance.fields[0]?.input.closest('.passenger-list__item');
            if(input){
                input.scrollIntoView();
            }
        }
    })
}
