import {createSeatSelectForm} from "@/widgets/select-seat";
import {createOrderForm} from "@/widgets/order";
import {useCallback} from "@/shared/useCallback";

export const createBookingFlow = ({isPassengersRender = null, onComplete=null} = {}) => {
    const onCompleteCb = useCallback(onComplete);

    const orderForm = createOrderForm({
        onShow: () => {
            if (
                typeof isPassengersRender === 'function' &&
                isPassengersRender()
            ) {
                orderForm.renderPassenegers();
            }
        },
        onValid: () => {
            orderForm.close();
            seatSelectForm.show();
        }
    });
    if (isPassengersRender === null) {
        orderForm.renderPassenegers();
    }

    const seatSelectForm = createSeatSelectForm({
        onClose: () => {
            orderForm.show();
        },
        onValid: () => {
            seatSelectForm.close(true);
            onCompleteCb.call();
        },
        onBackward: () => {
            seatSelectForm.close(true);
            orderForm.show();
        }
    });


    return {
        start: () => {
            orderForm.show();
            seatSelectForm.close();
        },
        startConversely: () => {
            orderForm.close();
            seatSelectForm.show();
        },
        onComplete: onCompleteCb.set
    }
};
