import {createModalWindow} from "@/shared/ui/modal-window";
import {useCallback} from "@/shared/useCallback";
import './styles.css'
import {orderStore} from "@/entity/order/store/order.store";
import {createPassengerSummaryTemplate} from "@/entity/order/ui/passenger-summary.template";
import {createOrderSummaryTemplate} from "@/entity/order/ui/order-summary.template";
import {createLoader} from "@/shared/ui/loader";

const viewTemplate = function ({passengerList, ...dto}) {
    const passengersSlot = passengerList.map(pass => createPassengerSummaryTemplate(pass)).join('');
    return createOrderSummaryTemplate({
        ...dto,
        passengersSlot
    })
};

export function confirmBuy({onDecline = null, onConfirm = null} = {}) {
    const onDeclineCb = useCallback(onDecline);
    const onConfirmCb = useCallback(onConfirm);
    const loader = createLoader();

    const target = document.getElementById('buy-confirmation-popup');
    const orderView = target.querySelector('.order-summary');
    const modal = createModalWindow({
        target,
        onShow(){
            orderView.innerHTML = viewTemplate(orderStore.getOrderView());
        }
    });
    const startLoader = () => {
        loader.startLoader(target);
    };
    const stopLoader = () => {
        loader.stopLoader();
    };

    target.querySelector('.action-btn--decline').addEventListener('click', onDeclineCb.call);
    target.querySelector('.action-btn--confirm').addEventListener('click', onConfirmCb.call);
    target.querySelector('.form-popup__close-btn').addEventListener('click', modal.close.bind(null, false));

    return {
        ...modal,
        startLoader,
        stopLoader,
        onDecline: onDeclineCb.set,
        onConfirm: onConfirmCb.set,
    }
}
