import './styles.css';
const SELECTED_CLASS = 'seat--selected';
const DISABLED_CLASS = 'seat--disabled';

const seatViewIdRegExp = /rf-(\d+-\d+)/;

const customStroke = '#4D4D4D';
const customFill = '#F1F1F1';
const customTextFill = '#4D4D4D';
const SVG_CENTER_OFFSET = '50%';

const disabledFill = 'rgb(230, 230, 230)';
const disabledStroke = 'rgb(175, 175, 175)';
const disabledTextFill = 'rgb(175, 175, 175)';

export const createSeatView = (source) => {
    const seatText = source.querySelector('g[id^="rn-"]');
    const seatView = source.querySelector('g[id^="rf-"]');
    const textNode = seatText ? seatText.querySelector('text') : null;
    const textWrapper = textNode ? textNode.querySelector('textPath') : null;

    const defaultText = textWrapper ? textWrapper.textContent : null;
    const defaultOffset = textWrapper ? textWrapper.getAttribute('startOffset') : null;
    const defaultFill = seatView ? seatView.firstElementChild.getAttribute('fill') : null;
    const defaultStroke = seatView ? seatView.firstElementChild.getAttribute('stroke') : null;
    const defaultTextFill = textWrapper ? textWrapper.parentElement.getAttribute('fill') : null;
    const defaultFontSize = textNode ? textNode.getAttribute('font-size') : null;
    const defaultFontFamily = textNode ? textNode.getAttribute('font-family') : null;
    const defaultLetterSpacing = textNode ? textNode.getAttribute('letter-spacing') : null;
    const setText = function (text) {
        if (textWrapper) {
            textWrapper.textContent = text;
            textWrapper.setAttribute('startOffset', SVG_CENTER_OFFSET);
        }
        return this;
    };
    const setSelected = function () {
        source.classList.add(SELECTED_CLASS);
        if (textWrapper) {
            textWrapper.parentElement.setAttribute('fill', customTextFill);
        }
        if (seatView) {
            seatView.lastElementChild.style.display = 'none';
            seatView.firstElementChild.setAttribute('stroke', customStroke);
            seatView.firstElementChild.setAttribute('fill', customFill);
        }
        if (textNode) {
            textNode.removeAttribute('font-size');
            textNode.removeAttribute('font-family');
            textNode.removeAttribute('letter-spacing');
        }
        return this;
    };

    const setDefault = function () {
        if(source.classList.contains(SELECTED_CLASS)){
            source.classList.remove(SELECTED_CLASS);
            if (textWrapper) {
                textWrapper.textContent = defaultText;
                textWrapper.setAttribute('startOffset', defaultOffset);
                textWrapper.parentElement.setAttribute('fill', defaultTextFill);
            }
            if (seatView) {
                seatView.lastElementChild.style.display = 'block';
                seatView.firstElementChild.setAttribute('fill', defaultFill);
                seatView.firstElementChild.setAttribute('stroke', defaultStroke);
            }
            if (textNode) {
                textNode.setAttribute('font-size',defaultFontSize);
                textNode.setAttribute('font-family',defaultFontFamily);
                textNode.setAttribute('letter-spacing',defaultLetterSpacing);
            }
        } else if(source.classList.contains(DISABLED_CLASS)) {
            source.classList.remove(DISABLED_CLASS);
            if (textWrapper) {
                textWrapper.parentElement.setAttribute('fill', defaultTextFill);
            }
            if (seatView) {
                seatView.firstElementChild.setAttribute('fill', defaultFill);
                seatView.firstElementChild.setAttribute('stroke', defaultStroke);
                seatView.lastElementChild.setAttribute('fill', defaultFill);
                seatView.lastElementChild.setAttribute('stroke', defaultStroke);
            }
        }

        return this;
    };
    const setDisabled = function () {
        source.classList.add(DISABLED_CLASS);
        if (textWrapper) {
            textWrapper.parentElement.setAttribute('fill', disabledTextFill);
        }
        if (seatView) {
            seatView.firstElementChild.setAttribute('fill', disabledFill);
            seatView.firstElementChild.setAttribute('stroke', disabledStroke);
            seatView.lastElementChild.setAttribute('fill', disabledFill);
            seatView.lastElementChild.setAttribute('stroke', disabledStroke);
        }
        return this;
    };
    const isDisabled = () => source.classList.contains(DISABLED_CLASS);
    const isSelected = () => source.classList.contains(SELECTED_CLASS);
    const getSeatNumber = () => {
        if (seatView) {
            return seatViewIdRegExp.exec(seatView.id)[1];
        }

        return null;
    };
    const getSeatPosition = () => getSeatNumber().split('-');

    return {
        setText,
        setDisabled,
        isDisabled,
        isSelected,
        setSelected,
        setDefault,
        getSeatNumber,
        getSeatPosition
    };
};
