export const createOrderPrice = () => {
    const el = document.querySelector('.form-popup__form-input--price');
    const priceSource = el.querySelector('.form-input__src');
    const passengerCostMap = new Map();

    const renderPrice = () => {
        let resultPrice = 0;
        for (let [, cost] of passengerCostMap.entries()) {
            resultPrice += +cost;
        }
        if (resultPrice === 0) {
            resultPrice = '0 000'
        }
        priceSource.textContent = `${resultPrice} ₽`;
    };
    const addPrice = (passengerKey, cost) => {
        passengerCostMap.set(passengerKey, cost);
        renderPrice();
    };
    const removePrice = (passengerKey) => {
        passengerCostMap.delete(passengerKey);
        renderPrice();
    };
    const removeAll = () => {
        passengerCostMap.clear();
        renderPrice();
    };

    renderPrice();
    return {
        addPrice,
        removePrice,
        removeAll,
    }
};
