import {Spinner} from 'spin.js';
import "spin.js/spin.css";
import "./style.css";

const opts = {
    lines: 13, // The number of lines to draw
    length: 12, // The length of each line
    width: 5, // The line thickness
    radius: 12, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    speed: 0.9, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-more', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#9d3737', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    zIndex: 2000000000, // The z-index (defaults to 2e9)
    className: 'spinner', // The CSS class to assign to the spinner
    position: 'absolute', // Element positioning
};

export const createLoader = () =>  {
    let loader = document.createElement('div');
    loader.className = 'loader';

    const startLoader = (target) => {
        target.append(loader);
        new Spinner(opts).spin(loader);
    };
    const stopLoader = () => {
        loader.remove();
    };

    return {
        startLoader,
        stopLoader,
    };
};