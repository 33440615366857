import {useCallback} from "@/shared/useCallback";

export const createDropdownToggle = (dropdownLabel, {onClick, cn, preventMousedown=false} = {}) => {
    const CLASS_DROPDOWN_SHOWN = cn ?? 'form-input--dropdown-shown';
    const onClickCb = useCallback(onClick);
    const showOpened = () => dropdownLabel.classList.add(CLASS_DROPDOWN_SHOWN);
    const showClosed = () => dropdownLabel.classList.remove(CLASS_DROPDOWN_SHOWN);

    if(onClickCb.exists()){
        dropdownLabel.addEventListener('click', (event) => {
            onClickCb.call(event);
        });
    }
    if(preventMousedown){
        dropdownLabel.addEventListener('mousedown', (event) => {
            event.preventDefault()
        })
    }

    return {
        showOpened,
        showClosed,
        onClick: onClickCb.set
    };
};
