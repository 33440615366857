import {createModalWindow} from "@/shared/ui/modal-window";

export const createOrderFormModal = ({target, onClose, onShow}) => {
    const modal = createModalWindow({
        target, onClose, onShow
    });
    target.querySelector('.form-popup__close-btn').addEventListener('click', () => {
        modal.close();
    });
    return modal;
};
