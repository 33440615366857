import SlimSelect from "slim-select";
import './styles.css'
import {useCallback} from "@/shared/useCallback";

export function createSelectMenu(
    {select, placeholderText, onSelect, onShow, onHide, options, showSearch = false, relative = false} = {}
) {
    const onSelectCb = useCallback(onSelect);
    const onShowCb = useCallback(onShow);
    const onHideCb = useCallback(onHide);
    const getPlaceholder = () => {
        let result = placeholderText;
        if (!result) {
            result = select.getAttribute('data-placeholder');
        }
        return result;
    };
    const createOptList = (options = []) => {
        const optList = Array.from(options);
        const placeholderText = select.getAttribute('data-placeholder');
        if (placeholderText) {
            const placeholder = {text: placeholderText, value: '', placeholder: true};
            optList.unshift(placeholder);
        }
        return optList;
    };
    const isNotSelectable = () => selectMenuInstance
        .getData()
        .filter(option => !option.placeholder && !option.selected).length === 0;
    const settings = {
        showSearch,
        searchText: 'Не найдено',
        searchPlaceholder: ' ',
        searchHighlight: false,
        placeholderText: getPlaceholder(),
    };
    if (relative) {
        settings.contentPosition = 'relative';
        settings.contentLocation = select.parentElement;
    }
    const HIGHLIGHTED_CLASS = 'ss-option--highlighted';
    let hoverController = null;
    const selectMenuInstance = new SlimSelect({
        select,
        data: createOptList(options),
        settings,
        events: {
            beforeChange: (newVal) => {
                let result = newVal;
                if (!selectMenuInstance.settings.isMultiple) {
                    result = result.length > 0 ? result[0] : null;
                }
                onSelectCb.call(result);
                return true;
            },
            afterChange: () => {
                select.dispatchEvent(new Event('input'));
                if (isNotSelectable()) {
                    selectMenuInstance.disable();
                } else {
                    selectMenuInstance.enable();
                }
            },
            beforeOpen: () => {
                hoverController = new AbortController();
                selectMenuInstance.render.getOptions().forEach((el) => {
                    el.addEventListener('mouseover', function () {
                        requestAnimationFrame(() => {
                            if(!this.classList.contains(HIGHLIGHTED_CLASS)){
                                selectMenuInstance.render.getOptions().forEach((el) => {
                                    el.classList.remove('ss-selected');
                                    el.classList.remove(HIGHLIGHTED_CLASS);
                                });
                                this.classList.add(HIGHLIGHTED_CLASS);
                            }
                        })
                    }, {signal: hoverController.signal})
                });
                onShowCb.call();
            },
            beforeClose: () => {
                if (hoverController !== null) {
                    hoverController.abort();
                    hoverController = null;
                    requestAnimationFrame(()=> {
                        selectMenuInstance.render.getOptions().forEach((el) => {
                            el.classList.remove(HIGHLIGHTED_CLASS);
                            if (el.getAttribute('aria-selected') === 'true') {
                                el.classList.add(HIGHLIGHTED_CLASS);
                            }
                        });
                    })
                }
                onHideCb.call();
            },
        }
    });
    // #region Pristine validation resolve for generated search input
    let searchInput = null;
    let searchInputParent = null;
    if (relative && !showSearch) {
        select.parentElement.querySelector('input[type="search"]').remove()
    } else {
        searchInput = select.parentElement.querySelector('input[type="search"]');
        if(searchInput){
            searchInput.addEventListener('click', (event) => {
                event.stopPropagation();
            });
            searchInputParent = searchInput.parentElement;
            searchInputParent.removeChild(searchInput);
        }
    }
    // #endregion
    const afterOptionsSetup = () => {
        const r = selectMenuInstance
            .getData()
            .filter((opt) => {
                return opt.placeholder === false;
            });
        if (r.length === 0) {
            selectMenuInstance.disable();
        } else {
            selectMenuInstance.enable();
        }
    };
    afterOptionsSetup();

    const detectMob = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    };

    const visibleBottom = function (target) {
        return window.scrollY + target.getBoundingClientRect().top < window.scrollY + document.documentElement.clientHeight
    };

    const toggleAction = (event) => {
        if (selectMenuInstance.settings.isOpen) {
            searchInputParent && searchInputParent.contains(searchInput) && searchInputParent.removeChild(searchInput);
            event.preventDefault();
            selectMenuInstance.close();
        } else {
            searchInputParent && searchInputParent.appendChild(searchInput);
            selectMenuInstance.open();
            if (document.body.contains(searchInputParent)) {
                setTimeout(el => {
                    if (detectMob() && document.body.contains(el) && !visibleBottom(el.nextSibling)) {
                        document.getElementById('buy-ticket').parentElement.scrollTop += 50 +
                            (window.scrollY + el.nextSibling.getBoundingClientRect().top) -
                            (window.scrollY + document.documentElement.clientHeight);
                    }
                }, 100, searchInputParent);
            }
        }
    };
    selectMenuInstance.render.main.main.addEventListener('click', (event) => {
        toggleAction(event);
    });
    return {
        onShow: onShowCb.set,
        onHide: onHideCb.set,
        setOptions: (data) => {
            selectMenuInstance.setData(createOptList(data));
            afterOptionsSetup();
        },
        selectOne: (value) => {
            selectMenuInstance.setSelected(value);
            selectMenuInstance.getSelected();
            selectMenuInstance.getData();
        },
        disable: selectMenuInstance.disable,
        enable: selectMenuInstance.enable,
        onSelect: onSelectCb.set,
        toggleAction,
    }
}
