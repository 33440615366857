export const paymentResultUrl = () => {
    const PAYMENT_RESULT_KEY = 'payment';
    const PAYMENT_RESULT_SUCCESS = 'success';
    const PAYMENT_RESULT_ERROR = 'failed';
    const params = new URLSearchParams(window.location.search);

    const isPaymentResult = () => params.has(PAYMENT_RESULT_KEY);
    const isSuccced = () => params.get(PAYMENT_RESULT_KEY) === PAYMENT_RESULT_SUCCESS;
    const isError = () => params.get(PAYMENT_RESULT_KEY) === PAYMENT_RESULT_ERROR;
    return {
        isPaymentResult,
        isSuccced,
        isError,
    }
};
