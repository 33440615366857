const createLegendItemTemplate = (className, color) => {
    const template = `<li class="select-seat__info-list-item select-seat__info-list-item--seat-standart" style="--seat-color:${color};">— билеты класса «${className}»</li>`;
    const buffer = document.createElement('div');
    buffer.innerHTML = template;
    return buffer.firstElementChild;
};
export const createSelectSeatLegend = ({form = null} = {}) => {
    const legend = (form ?? document).querySelector('.select-seat__info-list');
    const init = (roomClasses) => {
        legend.innerHTML = '';
        for(let roomClass of roomClasses){
            legend.append(
                createLegendItemTemplate(
                    roomClass['name'],
                    roomClass['color']
                )
            );
        }
    };

    return {
        init
    }
};
