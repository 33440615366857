import './styles.css';
import { useCallback } from "@/shared/useCallback";
import ResizeObserver from 'resize-observer-polyfill';

const CLASS_MODAL_LOCK = 'modal-lock';
const CLASS_MODAL_WINDOW = 'modal-window';
const CLASS_MODAL_WINDOW_ACTIVE = 'modal-window--active';
const CLASS_MODAL_OVERLAY = 'modal-window__overlay';
const CLASS_MODAL_OVERLAY_SPACED = 'modal-window__overlay--spaced';
const CLASS_MODAL_TARGET = 'modal-window__target';
const CLASS_MODAL_TARGET_ACTIVE = 'modal-window__target--active';

const createOverlay = () => {
    const overlay = document.createElement('div');
    overlay.classList.add(CLASS_MODAL_OVERLAY);
    return overlay;
};
const createWrapper = () => {
    const wrapper = document.createElement('div');
    wrapper.classList.add(CLASS_MODAL_WINDOW);
    document.body.append(wrapper);
    return wrapper;
};
export function createModalWindow({ target, onShow, onClose } = {}) {
    const onShowCb = useCallback(onShow);
    const onCloseCb = useCallback(onClose);

    target.classList.add(CLASS_MODAL_TARGET);
    const overlay = createOverlay();
    const wrapper =createWrapper();
    wrapper.append(overlay);
    overlay.append(target);
    const setupOverlaySpaces = () => {
        if(target.getBoundingClientRect().height > overlay.getBoundingClientRect().height){
            overlay.classList.add(CLASS_MODAL_OVERLAY_SPACED)
        }else{
            overlay.classList.remove(CLASS_MODAL_OVERLAY_SPACED)
        }
    };
    const windowObserver = new ResizeObserver(() => {
        setupOverlaySpaces()
    });
    windowObserver.observe(document.body);
    let closeController = null;
    const show = (silent = false) => {
        requestAnimationFrame(() => {
            document.body.classList.add(CLASS_MODAL_LOCK);
            target.classList.add(CLASS_MODAL_TARGET_ACTIVE);
            wrapper.classList.add(CLASS_MODAL_WINDOW_ACTIVE);
            requestAnimationFrame(() => setupOverlaySpaces());
        });
        if (closeController !== null) {
            closeController.abort();
        }
        closeController = new AbortController();
        wrapper.addEventListener('click', (event) => {
            if (event.target === wrapper || event.target === overlay) {
                closeController.abort();
                closeController = null;
                close();
            }
        }, { signal: closeController.signal });
        if(!silent){
            onShowCb.call();
        }
    };
    const close = (silent) => {
        if (closeController !== null) {
            closeController.abort();
        }
        requestAnimationFrame(() => {
            document.body.classList.remove(CLASS_MODAL_LOCK);
            target.classList.remove(CLASS_MODAL_TARGET_ACTIVE);
            wrapper.classList.remove(CLASS_MODAL_WINDOW_ACTIVE);
        });
        if (!silent) {
            onCloseCb.call();
        }
    };
    const isShown = () => wrapper.classList.contains(CLASS_MODAL_WINDOW_ACTIVE);

    return {
        show,
        close,
        isShown,
        onClose: onCloseCb.set,
        onShow: onShowCb.set,
    };
}
