import {useVKAnalytics} from "./use";
import {isProd} from "@/shared/isProd";

const VKAnalyticsSentGoals = [];
export function sendVKAnalyticsGoal(event) {
    if(isProd()) {

        if (!VKAnalyticsSentGoals.includes(event)) {
            console.info('Sending', event, 'event to VK');

            useVKAnalytics(function () {
                VK.Goal(event);
                VKAnalyticsSentGoals.push(event);
            });
        }
    }
}
