export function createCruiseList() {
    let itemsTitle = document.querySelectorAll('.cost__adaptive-name-title');
    const closeTitles = () => itemsTitle.forEach((item) => item.nextElementSibling.classList.remove('visible'));
    itemsTitle.forEach(function (item) {
        item.addEventListener('click', () => {
            if(item.nextElementSibling.classList.contains('visible')){
                closeTitles();
            }else{
                closeTitles();
                item.nextElementSibling.classList.toggle('visible');
            }
        });
    })
}
